<template>
  <div class="logo-component-container py-16">
    <h2 class="mb-3">Registrar contraseña</h2>
    <p>
      Esta es la primera vez que ingresas al sitio web, debes ingresar una contraseña para poder seguir utilizando la cuenta
    </p>
    <div class="login-form-container">
      <div class="login-form">
        <transition name="fadeHeight" mode="out-in">
          <alert-box
            v-if="error != '' || (Array.isArray(error) && error.length != 0)"
            class="login-alert-box my-2"
            type="error"
            :title="'Datos erróneos, favor de verificar'"
            :errors="error"
          />
        </transition>
        <input-password
          :must="['required']"
          placeholder="Ingresa la contraseña"
          @password="(value) => { form.password = value }"
        />
        <input-password
          :must="['required']"
          placeholder="Confirma la contraseña"
          @password="(value) => { form.password_confirmed = value }"
        />
        <button-component
          @whenClick="submit()"
          :loading="!canSubmit"
          :disabled="disabled"
        >
          Registrar la contraseña
        </button-component>
      </div>
    </div>
    <!-- <div class="login-link-group">
      <router-link class="login-link link-text--text" to="/">Inicio</router-link>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputPassword from '@/components/form/InputPassword.vue';
import AlertBox from '@/components/form/AlertBox.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'login',
  components: {
    'input-password': InputPassword,
    'button-component': Button,
    'alert-box': AlertBox,
  },
  data() {
    return {
      form: {
        password: '',
        password_confirmed: '',
      },
      errorEmail: false,
      show_pass: false,
      error: '',
      disabled: true,
      canSubmit: true,
    };
  },
  methods: {
    ...mapActions('authentication', [
      'setToken',
      'login'
    ]),
    ...mapActions('main', ['changePassword']),
    submit() {
      if (!this.canSubmit) {
        return;
      } 
      // Login Call
      this.canSubmit = false;
      this.changePassword({
        email: this.fullTokenDecoded.sub,
        password: this.form.password
      })
        .then(() => {
          this.login({
            username: this.fullTokenDecoded.sub,
            password: this.form.password
          })
            .then(() => {
              alert('Contraseña cambiada');
              this.$router.replace('home');
            })
            .catch(err => console.log(err));
          //this.$router.replace('home');
        })
        .catch(err => console.log(err));
    },
    setError(error) {
      if (!error) {
        this.error = [];
      } else {
        this.error.push(error);
      }
    },
    validatePasswords() {
      if (this.form.password != '' && this.form.password_confirmed != '' && this.form.password == this.form.password_confirmed) {
        this.disabled = false;
        this.error = '';
      } else if (this.form.password != this.form.password_confirmed) {
        this.error = ['Las contraseñas no coinciden'];
        setTimeout(() => {
          this.error = '';
        },10000);
        this.disabled = true;
      } else {
        this.disabled = true;
      }
    }
  },
  watch: {
    'form.password'() {
      this.validatePasswords();
    },
    'form.password_confirmed'() {
      this.validatePasswords();
    },
  },
  computed: {
    ...mapGetters({
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    }),
  }
};
</script>

<style scoped>
.logo-component-container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 500px;
  width: 100%;
}
.login-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-link {
  cursor: pointer;
  text-decoration: underline;
}
.login-link:not(:last-child) {
  margin-right: 1em;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.login-form .login-margin {
  margin-bottom: 1em;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.login-remember div {
  width: 50%;
}
.login-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-forgot {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.login-alert-box {
  margin-bottom: 0px;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>
